<template >
  <div>
    <div class="app-modal__box">
      <div class="app-modal__in">
        <div class="app-modal__header d-flex f-between p-5 m-title-modal">
          <p class="large--title m-0">График работы</p>
          <div>
            <el-button type="success" size="medium" plain>Сохранить</el-button>
            <el-button type="warning" size="medium" plain>Закрыть</el-button>
          </div>
        </div>
      </div>
      <!-- app-modal__header end -->

      <div class="app-modal__body p-5 pb-0">
        <div class="timeline-items__right rounded-sm w-100 p-4">
          <el-row :gutter="20">
            <el-col :span="24">
              <div class="app-form__group mb-4">
                <span class="input--label d-block mb-2">Название</span>
                <el-input
                  placeholder="Название"
                  v-model="input"
                  size="medium"
                ></el-input>
              </div>
            </el-col>
            <!-- end col -->

            <el-col :span="24">
              <el-row :gutter="20">
                <el-col :span="4">
                  <div class="itme-data-day">Понедельник</div>
                </el-col>
                <el-col :span="7">
                  <div class="itme-data-day">
                    <span class="mr-2">От </span>
                    <el-time-select
                      class="w-100"
                      v-model="valueOT"
                      size="medium"
                      :picker-options="{
                        start: '08:30',
                        step: '00:15',
                        end: '18:30',
                      }"
                      placeholder="../../.."
                    >
                    </el-time-select>
                  </div>
                </el-col>
                <el-col :span="7">
                  <div class="itme-data-day">
                    <span class="mr-2">До </span>
                    <el-time-select
                      class="w-100"
                      v-model="valueDO"
                      size="medium"
                      :picker-options="{
                        start: '08:30',
                        step: '00:15',
                        end: '18:30',
                      }"
                      placeholder="../../.."
                    >
                    </el-time-select>
                  </div>
                </el-col>
                <el-col :span="6">
                  <div class="itme-data-day">
                    <el-input
                      placeholder="часов"
                      v-model="input"
                      size="medium"
                    ></el-input>
                    <el-checkbox v-model="checked" class="ml-2"></el-checkbox>
                  </div>
                </el-col>
                <!-- end col -->
              </el-row>
              <!-- end row -->
            </el-col>
            <!-- end col-24 -->
            <el-col :span="24">
              <el-row :gutter="20">
                <el-col :span="4">
                  <div class="itme-data-day">Вторник</div>
                </el-col>
                <el-col :span="7">
                  <div class="itme-data-day">
                    <span class="mr-2">От </span>
                    <el-time-select
                      class="w-100"
                      v-model="valueOT"
                      size="medium"
                      :picker-options="{
                        start: '08:30',
                        step: '00:15',
                        end: '18:30',
                      }"
                      placeholder="../../.."
                    >
                    </el-time-select>
                  </div>
                </el-col>
                <el-col :span="7">
                  <div class="itme-data-day">
                    <span class="mr-2">До </span>
                    <el-time-select
                      class="w-100"
                      v-model="valueDO"
                      size="medium"
                      :picker-options="{
                        start: '08:30',
                        step: '00:15',
                        end: '18:30',
                      }"
                      placeholder="../../.."
                    >
                    </el-time-select>
                  </div>
                </el-col>
                <el-col :span="6">
                  <div class="itme-data-day">
                    <el-input
                      placeholder="часов"
                      v-model="input"
                      size="medium"
                    ></el-input>
                    <el-checkbox v-model="checked" class="ml-2"></el-checkbox>
                  </div>
                </el-col>
                <!-- end col -->
              </el-row>
              <!-- end row -->
            </el-col>
            <!-- end col-24 -->
            <el-col :span="24">
              <el-row :gutter="20">
                <el-col :span="4">
                  <div class="itme-data-day">Среда</div>
                </el-col>
                <el-col :span="7">
                  <div class="itme-data-day">
                    <span class="mr-2">От </span>
                    <el-time-select
                      class="w-100"
                      v-model="valueOT"
                      size="medium"
                      :picker-options="{
                        start: '08:30',
                        step: '00:15',
                        end: '18:30',
                      }"
                      placeholder="../../.."
                    >
                    </el-time-select>
                  </div>
                </el-col>
                <el-col :span="7">
                  <div class="itme-data-day">
                    <span class="mr-2">До </span>
                    <el-time-select
                      class="w-100"
                      v-model="valueDO"
                      size="medium"
                      :picker-options="{
                        start: '08:30',
                        step: '00:15',
                        end: '18:30',
                      }"
                      placeholder="../../.."
                    >
                    </el-time-select>
                  </div>
                </el-col>
                <el-col :span="6">
                  <div class="itme-data-day">
                    <el-input
                      placeholder="часов"
                      v-model="input"
                      size="medium"
                    ></el-input>
                    <el-checkbox v-model="checked" class="ml-2"></el-checkbox>
                  </div>
                </el-col>
                <!-- end col -->
              </el-row>
              <!-- end row -->
            </el-col>
            <!-- end col-24 -->
            <el-col :span="24">
              <el-row :gutter="20">
                <el-col :span="4">
                  <div class="itme-data-day">Четверг</div>
                </el-col>
                <el-col :span="7">
                  <div class="itme-data-day">
                    <span class="mr-2">От </span>
                    <el-time-select
                      class="w-100"
                      v-model="valueOT"
                      size="medium"
                      :picker-options="{
                        start: '08:30',
                        step: '00:15',
                        end: '18:30',
                      }"
                      placeholder="../../.."
                    >
                    </el-time-select>
                  </div>
                </el-col>
                <el-col :span="7">
                  <div class="itme-data-day">
                    <span class="mr-2">До </span>
                    <el-time-select
                      class="w-100"
                      v-model="valueDO"
                      size="medium"
                      :picker-options="{
                        start: '08:30',
                        step: '00:15',
                        end: '18:30',
                      }"
                      placeholder="../../.."
                    >
                    </el-time-select>
                  </div>
                </el-col>
                <el-col :span="6">
                  <div class="itme-data-day">
                    <el-input
                      placeholder="часов"
                      v-model="input"
                      size="medium"
                    ></el-input>
                    <el-checkbox v-model="checked" class="ml-2"></el-checkbox>
                  </div>
                </el-col>
                <!-- end col -->
              </el-row>
              <!-- end row -->
            </el-col>
            <!-- end col-24 -->
            <el-col :span="24">
              <el-row :gutter="20">
                <el-col :span="4">
                  <div class="itme-data-day">Пятница</div>
                </el-col>
                <el-col :span="7">
                  <div class="itme-data-day">
                    <span class="mr-2">От </span>
                    <el-time-select
                      class="w-100"
                      v-model="valueOT"
                      size="medium"
                      :picker-options="{
                        start: '08:30',
                        step: '00:15',
                        end: '18:30',
                      }"
                      placeholder="../../.."
                    >
                    </el-time-select>
                  </div>
                </el-col>
                <el-col :span="7">
                  <div class="itme-data-day">
                    <span class="mr-2">До </span>
                    <el-time-select
                      class="w-100"
                      v-model="valueDO"
                      size="medium"
                      :picker-options="{
                        start: '08:30',
                        step: '00:15',
                        end: '18:30',
                      }"
                      placeholder="../../.."
                    >
                    </el-time-select>
                  </div>
                </el-col>
                <el-col :span="6">
                  <div class="itme-data-day">
                    <el-input
                      placeholder="часов"
                      v-model="input"
                      size="medium"
                    ></el-input>
                    <el-checkbox v-model="checked" class="ml-2"></el-checkbox>
                  </div>
                </el-col>
                <!-- end col -->
              </el-row>
              <!-- end row -->
            </el-col>
            <!-- end col-24 -->
            <el-col :span="24">
              <el-row :gutter="20">
                <el-col :span="4">
                  <div class="itme-data-day">Субота</div>
                </el-col>
                <el-col :span="7">
                  <div class="itme-data-day">
                    <span class="mr-2">От </span>
                    <el-time-select
                      class="w-100"
                      v-model="valueOT"
                      size="medium"
                      :picker-options="{
                        start: '08:30',
                        step: '00:15',
                        end: '18:30',
                      }"
                      placeholder="../../.."
                    >
                    </el-time-select>
                  </div>
                </el-col>
                <el-col :span="7">
                  <div class="itme-data-day">
                    <span class="mr-2">До </span>
                    <el-time-select
                      class="w-100"
                      v-model="valueDO"
                      size="medium"
                      :picker-options="{
                        start: '08:30',
                        step: '00:15',
                        end: '18:30',
                      }"
                      placeholder="../../.."
                    >
                    </el-time-select>
                  </div>
                </el-col>
                <el-col :span="6">
                  <div class="itme-data-day">
                    <el-input
                      placeholder="часов"
                      v-model="input"
                      size="medium"
                    ></el-input>
                    <el-checkbox v-model="checked" class="ml-2"></el-checkbox>
                  </div>
                </el-col>
                <!-- end col -->
              </el-row>
              <!-- end row -->
            </el-col>
            <!-- end col-24 -->
            <el-col :span="24">
              <el-row :gutter="20">
                <el-col :span="4">
                  <div class="itme-data-day">Воскресения</div>
                </el-col>
                <el-col :span="7">
                  <div class="itme-data-day">
                    <span class="mr-2">От </span>
                    <el-time-select
                      class="w-100"
                      v-model="valueOT"
                      size="medium"
                      :picker-options="{
                        start: '08:30',
                        step: '00:15',
                        end: '18:30',
                      }"
                      placeholder="../../.."
                    >
                    </el-time-select>
                  </div>
                </el-col>
                <el-col :span="7">
                  <div class="itme-data-day">
                    <span class="mr-2">До </span>
                    <el-time-select
                      class="w-100"
                      v-model="valueDO"
                      size="medium"
                      :picker-options="{
                        start: '08:30',
                        step: '00:15',
                        end: '18:30',
                      }"
                      placeholder="../../.."
                    >
                    </el-time-select>
                  </div>
                </el-col>
                <el-col :span="6">
                  <div class="itme-data-day">
                    <el-input
                      placeholder="часов"
                      v-model="input"
                      size="medium"
                    ></el-input>
                    <el-checkbox v-model="checked" class="ml-2"></el-checkbox>
                  </div>
                </el-col>
                <!-- end col -->
              </el-row>
              <!-- end row -->
            </el-col>
            <!-- end col-24 -->
            <el-col :span="24"><div class="linya"></div></el-col>

            <el-col :span="12">
              <div class="app-form__group mt-4">
                <!-- <span class="input--label d-block mb-2">Оплата за</span> -->
                <el-switch
                  v-model="value"
                  active-text="Час"
                  inactive-text="Месяц"
                  class="mb-2"
                >
                </el-switch>
                <el-input
                  placeholder="Оплата за"
                  v-model="input"
                  size="medium"
                ></el-input>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
      <!-- end app-modal__body -->
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      num: 1,
      value1: true,
      valueOT: "",
      valueDO: "",
      input: "",
      options: [
        {
          value: "Option1",
          label: "Option1",
        },
        {
          value: "Option2",
          label: "Option2",
        },
        {
          value: "Option3",
          label: "Option3",
        },
        {
          value: "Option4",
          label: "Option4",
        },
        {
          value: "Option5",
          label: "Option5",
        },
      ],
      options2: [
        {
          value: "HTML",
          label: "HTML",
        },
        {
          value: "CSS",
          label: "CSS",
        },
        {
          value: "JavaScript",
          label: "JavaScript",
        },
      ],
      value: "",
      value2: [],
      value3: [],
    };
  },
  components: {},
  mounted() {},
  methods: {},
};
</script>
