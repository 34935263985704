<template >
  <div>
    <div class="app-modal__box">
      <div class="app-modal__in">
        <div class="app-modal__header d-flex f-between p-5 m-title-modal">
          <p class="large--title m-0">Добавить тест</p>
          <div>
            <el-button type="success" size="medium" plain>Сохранить</el-button>
            <el-button type="warning" size="medium" plain>Закрыть</el-button>
          </div>
        </div>
      </div>
      <!-- app-modal__header end -->

      <div class="app-modal__body p-5 pb-0">
        <div class="timeline-items__right rounded-sm w-100 p-4">
          <el-row :gutter="20">
            <el-col :span="12">
              <div class="app-form__group mb-4">
                <span class="input--label d-block mb-2">Кандидат</span>
                <el-input
                  placeholder="Кандидат"
                  v-model="input"
                  size="medium"
                ></el-input>
              </div>
            </el-col>
            <!-- end col -->

            <el-col :span="12">
              <div class="app-form__group mb-4">
                <span class="input--label d-block mb-2">Отдел</span>
                <el-input
                  placeholder="Цех"
                  v-model="input"
                  size="medium"
                  :disabled="true"
                ></el-input>
              </div>
            </el-col>
            <!-- end col -->

            <el-col :span="24">
              <div class="app-form__group mb-4">
                <span class="input--label d-block mb-2">Название</span>
                <el-input
                  placeholder="Название"
                  v-model="input"
                  size="medium"
                ></el-input>
              </div>
            </el-col>
            <!-- end col -->

            <el-col :span="16">
              <div class="app-form__group mb-4">
                <span class="input--label d-block mb-1">Дата сдачи теста</span>
                <el-row :gutter="20">
                  <el-col :span="12">
                    <div class="itme-data-day">
                      <span class="mr-2">От </span>
                      <el-time-select
                        class="w-100"
                        v-model="valueOT"
                        size="medium"
                        :picker-options="{
                          start: '08:30',
                          step: '00:15',
                          end: '18:30',
                        }"
                        placeholder="../../.."
                      >
                      </el-time-select>
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <div class="itme-data-day">
                      <span class="mr-2">До </span>
                      <el-time-select
                        class="w-100"
                        v-model="valueDO"
                        size="medium"
                        :picker-options="{
                          start: '08:30',
                          step: '00:15',
                          end: '18:30',
                        }"
                        placeholder="../../.."
                      >
                      </el-time-select>
                    </div>
                  </el-col>
                </el-row>
                <!-- end row -->
              </div>
            </el-col>
            <!-- end col -->

            <el-col :span="8">
              <div class="app-form__group mb-4">
                <span class="input--label d-block mb-3">Дата сдачи теста</span>
                <el-date-picker
                  v-model="value1"
                  type="date"
                  size="medium"
                  placeholder="Дата сдачи теста"
                  class="w-100"
                >
                </el-date-picker>
              </div>
            </el-col>
            <!-- end col -->
            <el-col :span="24">
              <div class="app-form__group mb-4">
                <span class="input--label d-block mb-2">Описания</span>
                <el-input
                  type="textarea"
                  size="medium"
                  placeholder="Описания"
                  v-model="input"
                ></el-input>
              </div>
            </el-col>
            <!-- end col -->
          </el-row>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
export default {
  components: {},
  data() {
    return {
      num: 1,
      value1: true,
      drawer: false,
      input: "",
      options: [
        {
          value: "Option1",
          label: "Option1",
        },
        {
          value: "Option2",
          label: "Option2",
        },
        {
          value: "Option3",
          label: "Option3",
        },
        {
          value: "Option4",
          label: "Option4",
        },
        {
          value: "Option5",
          label: "Option5",
        },
      ],
      options2: [
        {
          value: "HTML",
          label: "HTML",
        },
        {
          value: "CSS",
          label: "CSS",
        },
        {
          value: "JavaScript",
          label: "JavaScript",
        },
      ],
      value: "",
      value2: [],
      value3: [],
    };
  },
  mounted() {},
  methods: {},
};
</script>
