<template >
  <div>
    <div class="app-modal__box">
      <div class="app-modal__in">
        <div class="app-modal__header d-flex f-between p-5 m-title-modal">
          <p class="large--title m-0">Принять</p>
          <div>
            <el-button type="success" size="medium" plain>Сохранить</el-button>
            <el-button type="warning" size="medium" plain>Закрыть</el-button>
          </div>
        </div>
      </div>
      <!-- app-modal__header end -->

      <div class="app-modal__body p-5 pb-0">
        <div class="timeline-items__right rounded-sm w-100 p-4">
          <el-row :gutter="20">
            <el-col :span="12">
              <div class="app-form__group mb-4">
                <span class="input--label d-block mb-2">Фамилия</span>
                <el-input
                  placeholder="Фамилия"
                  v-model="input"
                  size="medium"
                  :disabled="true"
                ></el-input>
              </div>

              <div class="app-form__group mb-4">
                <span class="input--label d-block mb-2">Отчество</span>
                <el-input
                  placeholder="Отчество"
                  v-model="input"
                  size="medium"
                ></el-input>
              </div>

              <div class="app-form__group mb-4">
                <span class="input--label d-block mb-2">Должность</span>
                <el-select
                  class="w-100"
                  v-model="value1"
                  placeholder="Должность"
                  size="medium"
                >
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </div>

              <div class="app-form__group mb-4">
                <span class="input--label d-block mb-2">Пользователь</span>
                <el-select
                  class="w-100"
                  v-model="value2"
                  placeholder="Пользователь"
                  size="medium"
                >
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </div>

              <div class="app-form__group mb-4">
                <span class="input--label d-block mb-2"
                  >Испитательный период</span
                >
                <el-input-number
                  v-model="num"
                  controls-position="right"
                  :min="1"
                  class="w-100"
                  size="medium"
                ></el-input-number>
              </div>

              <div class="app-form__group mb-4">
                <span class="input--label d-block mb-2">Валюта</span>
                <el-select
                  class="w-100"
                  v-model="value"
                  placeholder="Валюта"
                  size="medium"
                >
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </div>

              <div class="app-form__group mb-4">
                <span class="input--label d-block mb-2">Оплата за</span>
                <el-switch
                  v-model="value"
                  active-text="Час"
                  inactive-text="Месяц"
                  class="mb-2"
                >
                </el-switch>
                <el-input
                  placeholder="Оплата за"
                  v-model="input"
                  size="medium"
                ></el-input>
              </div>
            </el-col>
            <!-- end col -->

            <el-col :span="12">
              <div class="app-form__group mb-4">
                <span class="input--label d-block mb-2">Имя</span>
                <el-input
                  placeholder="Имя"
                  v-model="input"
                  size="medium"
                ></el-input>
              </div>

              <div class="app-form__group mb-4">
                <span class="input--label d-block mb-2">Отдел</span>
                <el-select
                  class="w-100"
                  v-model="value"
                  placeholder="Отдел"
                  size="medium"
                >
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </div>

              <div class="app-form__group mb-4">
                <span class="input--label d-block mb-2">Оплачиваемый</span>
                <el-select
                  class="w-100"
                  v-model="value"
                  placeholder="Оплачиваемый"
                  size="medium"
                >
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </div>

              <div class="app-form__group mb-4">
                <el-row :gutter="20">
                  <el-col :span="22">
                    <span class="input--label d-block mb-2">График работы</span>
                    <el-select
                      class="w-100"
                      v-model="value"
                      placeholder="График работы"
                      size="medium"
                    >
                      <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      ></el-option>
                    </el-select>
                  </el-col>
                  <el-col :span="2">
                    <div class="pulus mtl" @click="drawer = true">
                      <i class="el-icon-plus"></i>
                    </div>
                  </el-col>
                </el-row>
              </div>

              <div class="app-form__group mb-4">
                <span class="input--label d-block mb-2">Оценка</span>
                <el-input
                  placeholder="Оценка"
                  v-model="input"
                  size="medium"
                ></el-input>
              </div>
            </el-col>
            <!-- end col -->
          </el-row>
        </div>
      </div>
      <!-- end app-modal__body -->
      <div class="app-modal app-modal__full">
        <el-drawer
          title="I am the title"
          :visible.sync="drawer"
          :with-header="false"
          :append-to-body="true"
          size="70%"
        >
          <DisableData />
        </el-drawer>
      </div>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
import DisableData from "./disable";
export default {
  components: {
    DisableData,
  },
  data() {
    return {
      num: 1,
      value1: true,
      drawer: false,
      input: "",
      options: [
        {
          value: "Option1",
          label: "Option1",
        },
        {
          value: "Option2",
          label: "Option2",
        },
        {
          value: "Option3",
          label: "Option3",
        },
        {
          value: "Option4",
          label: "Option4",
        },
        {
          value: "Option5",
          label: "Option5",
        },
      ],
      options2: [
        {
          value: "HTML",
          label: "HTML",
        },
        {
          value: "CSS",
          label: "CSS",
        },
        {
          value: "JavaScript",
          label: "JavaScript",
        },
      ],
      value: "",
      value2: [],
      value3: [],
    };
  },
  mounted() {},
  methods: {},
};
</script>
